<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="flex-grow-1 d-flex align-center justify-center my-2">
      <pie-chart
        :height="height"
        :chartdata="chartData"
        :options="options"
      />
    </div>
    <div v-if="!config.hideLegend">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="d-flex flex-wrap"
        :class="{'mb-2': parseInt(index) !== (data.length - 1)}"
      >
        <div class="d-flex flex-fill align-center mx-2">
          <v-icon v-if="item.icon" :color="colors[index]" size="14" left>
            {{ item.icon }}
          </v-icon>
  
          <div class="text-capitalize legend-text">
            {{ item.label }}
          </div>
        </div>
        <div class="d-flex flex-fill align-center justify-end total-number">
          {{ getNumberFormat(item.total) }} ({{ (total > 0 ? ((100 * item.total) / total) : 0).toFixed() }}%)
        </div>
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import PieChart from "@/sharedComponents/charts/PieChart";
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import _ from "lodash";

export default {
  name: "PieChartWidget",
  components: {
    PieChart,
    AppWidgetBlock,
  },
  props: {
    config: {
      default: () => ({
        size: '1x2',
        name: 'Pie Chart',
        hideLegend: false,
      })
    },
    data: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 160
    }
  },
  data(){
    return {
      options: {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              const index = tooltipItem.index;
              
              return data.labels[index] + ": " + (data.datasets[0].data[index]).toLocaleString();
            },
          },
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }],
        }
      },
      colors: [
        '#4682e4',
        '#eb716d',
        '#92d7d5',
        '#e3a24a',
      ],
    }
  },
  computed: {
    chartData() {
      return {
        datasets: [
          {
            datalabels: {
              display: false,
            },
            backgroundColor: this.colors,
            borderWidth: 0,
            data: this.data.map(item => item.total),
          },
        ],
        labels: this.data.map(item => _.startCase(item.label)),
      }
    },
    total() {
      return this.data.reduce((partialSum, a) => partialSum + parseInt(a.total), 0)
    },
  },
  watch: {
  },
  created() {
  },
  methods: {
    getNumberFormat(number) {
      return number.toLocaleString();
    }
  },
};
</script>

<style lang="scss" scoped>
.total-number {
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #66788e;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
</style>
