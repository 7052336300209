<template>
  <app-widget-block
    v-bind="{ config }"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="d-flex">
      <div
        v-if="items.length > 1"
        class="side-bar"
      >
        <v-btn
          v-for="item in items"
          :key="item.label"
          block
          depressed
          text
          class="justify-space-between side-bar-item"
          height="58"
          :class="{'v-btn--has-bg primary--text': selectedDataType === item.value}"
          @click="selectedDataType = item.value"
        >
          <span class="text-left">
            <span class="text-none stat d-block">{{ item.stat }}</span>
            <span class="text-none caption d-block">{{ item.label }}</span>
          </span>
          <span class="text-right">
            <v-icon size="12" class="right">
              $chevron_down_thin
            </v-icon>
          </span>
        </v-btn>
      </div>

      <v-divider
        v-if="items.length > 1"
        vertical
        inset
        class="mx-4"
      />

      <div class="chart-container">
        <line-chart
          :height="lineChartHeight"
          :chartdata="chartData"
          :options="options" 
        />
      </div>
    </div>
  </app-widget-block>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import LineChart from "@/sharedComponents/charts/LineChart";

export default {
  name: "LineChartWidget",
  components: {
    LineChart,
    AppWidgetBlock,
  },
  props: {
    config: {
      default: () => ({
        size: "2x1",
        name: "Line Chart",
      }),
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDataType: this.config?.defaultDataType ?? Object.keys(this.data)[0],
    };
  },
  computed: {
    lineChartHeight() {
      return this.config?.lineChartHeight;
    },
    options() {
      const max = Math.max(...(this.selectedData?.chartData?.data ?? []));
      let stepSize = Math.max(...(this.selectedData?.chartData?.data ?? [])) / 4;
      if (max >= 4) {
        stepSize = Math.round(stepSize);
      }
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function(tooltipItem) {
              return parseInt(tooltipItem.value).toLocaleString();
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize,
                callback: function(value) {
                  return value.toLocaleString();
                }
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      };
    },
    items() {
      return this.data ?? [];
    },
    selectedData() {
      return this.data[this.selectedDataType] ?? [];
    },
    chartData() {
      return {
        datasets: [
          {
            data: this.selectedData?.chartData?.data ?? [],
            datalabels: {
              display: false,
            },
            backgroundColor: this.selectedData?.bgColor ?? "rgba(43, 132, 235, 0.05)",
            borderColor: this.selectedData?.borderColor ?? "#2b84eb",
            pointBackgroundColor: this.selectedData?.ptBgColor ?? "#2b84eb",
            borderWidth: 2,
            pointBorderColor: "white",
            pointBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            pointHoverBorderColor: "white",
            pointHoverBorderWidth: 2,
            pointHoverRadius: 4,
          },
        ],
        labels: this.selectedData?.chartData?.label ?? [],
      };
    },
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        if (this.selectedDataType !== undefined) {
          this.$set(this, 'selectedDataTypes', val.defaultDataType ?? Object.keys(this.data)[0]);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  width: 200px;
}

.right {
  transform: rotate(-90deg);
}

.down {
  transform: rotate(90deg);
}

.chart-title {
  font-size: 15px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.side-bar-item:not(.primary--text) .caption {
  color: #66788e !important;
}

.caption {
  font-weight: normal !important;
  font-size: 12px !important;
  font-family: "Open Sans", sans-serif !important;
}

.stat {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
}

.chart-container {
  width: 100%;
}
</style>
