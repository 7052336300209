<template>
  <app-widget-block
    v-bind="{ config }"
    class="container"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <v-simple-table v-if="config.useTooltip">
      <thead>
        <tr class="search-table__header">
          <th v-for="(field, i) in data.headers" :key="i">
            {{ field.text }}
          </th>
        </tr>
      </thead>
      <tbody class="events-table__content">
        <template>
          <tr v-for="(item, i) in data.items" :key="i">
            <td class="py-1">
              {{ item.source_name }}
            </td>
            <td class="py-1 tool">
              <v-tooltip right>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="tags"
                    v-on="on"
                  >
                    {{ item.banner_creative_name }}
                  </span>
                </template>
                <span>{{ item.banner_creative_name }}</span>
              </v-tooltip>
            </td>
            <td class="py-1">
              {{ item.impressions }}
            </td>
            <td class="py-1">
              {{ item.clicks }}
            </td>
            <td class="py-1">
              {{ item.ctr }}
            </td>
          </tr>
        </template>
      </tbody>
      <template
        v-if="data.showTotals"
        slot="body.append"
      >
        <tr>
          <th
            v-for="(header, index) in data.headers"
            :key="'total-row-' + header.value"
          >
            <template v-if="index === 0">
              Total
            </template>
            <template v-else-if="header.totalValue">
              {{ header.totalValue }}
            </template>
            <template v-else-if="header.canTotal">
              {{ getTotalForHeader(header.value) }}
            </template>
            <template v-else-if="header.canAverage">
              {{ getAvgForHeader(header.value, header.averageAsPercentage) }}
            </template>
          </th>
        </tr>
      </template>
    </v-simple-table>

    <v-data-table
      v-else
      :headers="data.headers"
      :items="data.items"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hide-default-footer
      class="flex-grow-1 flex-shrink-0"
    >
      <template
        v-if="data.showTotals"
        slot="body.append"
      >
        <tr>
          <th
            v-for="(header, index) in data.headers"
            :key="'total-row-' + header.value"
          >
            <template v-if="index === 0">
              Total
            </template>
            <template v-else-if="header.totalValue">
              {{ header.totalValue }}
            </template>
            <template v-else-if="header.canTotal">
              {{ getTotalForHeader(header.value) }}
            </template>
            <template v-else-if="header.canAverage">
              {{ getAvgForHeader(header.value, header.averageAsPercentage) }}
            </template>
          </th>
        </tr>
      </template>
    </v-data-table>
  </app-widget-block>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";

export default {
  name: "DataTableWidget",
  components: {
    AppWidgetBlock,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sortBy: this.config?.defaultSortBy ?? '',
      sortDesc: this.config?.defaultSortDesc ?? false,
    };
  },
  methods: {
    getTotalForHeader(header, asFloat) {
      return this.data?.items?.reduce((partialSum, item) => {
        const val = asFloat
          ? parseFloat(item[header].replace(',', ''))
          : parseInt(item[header].replace(',', ''));
        return partialSum + val;
      }, 0).toLocaleString();
    },
    getAvgForHeader(header, asPercentage) {
      const total = this.getTotalForHeader(header, true);

      return (total / this.data?.items?.length).toPrecision(3).toLocaleString() + (asPercentage ? '%' : '');
    },
  },
};
</script>

<style scoped>
.container {
  max-height: 100%;
  overflow-y: scroll;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.tags {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: inline-block;
}
.tooltip {
  visibility: hidden;
  background: #fff;
  width: 150px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  top: -5px;
  left: 99%;
  border: 2px solid grey;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tool:hover > .tooltip {
  visibility: visible;
}
</style>